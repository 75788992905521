import { Injectable } from "@angular/core"

import { map } from "rxjs/operators"
import * as _ from "lodash"

import { AccountInfo, AccountService } from "./account.service"
import { getSafe, getCardValue } from "../utils/misc.utils"
import { daysBetween, getSafeDate, getTimezoneOffset } from "../utils/date.utils"
import { convertObjectToArray, stringSort } from "../utils/collections.utils"

@Injectable()
export class OrdersService {
    public orderTurnouts: {Turnout_ID: string, TurnoutDesc: string, AdvanceDays: number, CutoffHour: number, CutoffMinutes: number, DefaultFlow?: number}[] = []

    constructor (private accountService: AccountService) {}

    public getOrderCards () {
        return this.accountService.selectedAccount.pipe(map(account => this.formatCards(_.cloneDeep(account))))
    }

    private formatCards (accountInfo: AccountInfo) {
        const [data, layout] = [accountInfo.data, accountInfo.layout["Orders"]]
        const configuration = _.cloneDeep(layout.Configuration) || {}
        const layoutCards = Object.assign({}, layout)
        delete layoutCards.Configuration

        // Add additional handling for special configuration options
        configuration.getDefaultHour = () => (getSafe(() => this.accountService.selectedAccount.getValue().data.OrdersDefaultHour) || configuration.DefaultHour)
        configuration.getDefaultMinutes = () => (getSafe(() => this.accountService.selectedAccount.getValue().data.OrdersDefaultMinutes) || configuration.DefaultMinutes)
        configuration.getDefaultOffHour = () => (getSafe(() => this.accountService.selectedAccount.getValue().data.OrdersDefaultOffHour) || configuration.DefaultHour)
        configuration.getDefaultOffMinutes = () => (getSafe(() => this.accountService.selectedAccount.getValue().data.OrdersDefaultOffMinutes) || configuration.DefaultMinutes)

        // Add default values
        configuration.Contact = configuration.Contact || "Hide"
        configuration.TimezoneOffset = getTimezoneOffset(accountInfo.layout.Configuration.TimeZone)
        configuration.minDate = getSafeDate(configuration.DateRequestMinValue) ?? getSafeDate(`${new Date().getFullYear() - configuration.DateRequestYearsAvailable}/01/01`)

        return convertObjectToArray(layoutCards, {sortKeysBy: stringSort}).map(card => {
            if (!card || !data) {
                return {}
            }

            switch (card.Type) {
                case "Html": {
                    return {
                        type: "Html",
                        title: card.Title,
                        html: getCardValue(card.Data, data)
                    }
                }
                case "Orders": {
                    this.orderTurnouts = data.OrderTurnouts || []
        
                    const columnConfig = {skipKeys: ["Mobile", "MobileInfo"]}
                    const orderColumns = convertObjectToArray(card.OrderColumns, columnConfig).sort((a, b) => a.ColumnOrder - b.ColumnOrder)
                    const modificationColumns = convertObjectToArray(card.ModificationColumns, columnConfig).sort((a, b) => a.ColumnOrder - b.ColumnOrder)
                    const rejectedColumns = convertObjectToArray(card.RejectedColumns, columnConfig).sort((a, b) => a.ColumnOrder - b.ColumnOrder)
        
                    const ordersData = (data.OrdersCurrent || []).reduce((acc, order) => {
                        if (!["turnout", "ordersReadOnlyTurnout"].includes(data.permissionType) || data.turnouts.some(turnout => turnout === order.Turnout_ID)) {
                            order.OrderStatus = this.checkUnderReview(order.Turnout_ID, order.OrderStatus, order.EffectiveDate, configuration)
                            acc.push(order)
                        }
                        return acc
                    }, [])
                    const pendingOrders = ordersData.filter(order => order.OrderStatus === "Pending" && order.RequestStatus !== "Rejected")
                    const rejectedOrders = ordersData.filter(order => order.RequestStatus === "Rejected")
                    const underReviewOrders = ordersData.filter(order => order.OrderStatus === "Under Review" && order.RequestStatus !== "Rejected")
                    const scheduledOrders = ordersData.filter(order => order.OrderStatus === "Scheduled")
                    const pastOrders = (data.OrdersPast as any[] || []).map(order => {
                        order.OrderStatus = this.checkUnderReview(order.Turnout_ID, order.OrderStatus, order.EffectiveDate, configuration)
                        return order
                    })
        
                    const modificationData = (data.OrderChangeRequests as any[] || []).map(mod => {
                        mod.RequestStatus = this.checkUnderReview(mod.Turnout_ID, mod.RequestStatus, mod.ChangeTime, configuration)
                        return mod
                    })
                    
                    const turnouts = getSafe(() => 
                        ["turnout", "ordersReadOnlyTurnout"].includes(data.permissionType) ?
                        data.OrderTurnouts.filter(orderTurnout => data.turnouts.some(turnout => turnout === orderTurnout.Turnout_ID)) :
                        data.OrderTurnouts
                    ) || []
        
                    return {
                        showingPrior: data.OrdersShowingPrior,
                        configuration,
                        topNoteText: card.TopNoteText,
                        topNoteStyle: card.TopNoteStyle,
                        topNoteID: card.TopNoteID,
                        bottomNoteText: card.BottomNoteText,
                        bottomNoteStyle: card.BottomNoteStyle,
                        bottomNoteID: card.BottomNoteID,
                        turnouts,
                        contacts: data.OrderContacts || [],
                        orderColumns: orderColumns.map(column => ({
                            key: column.FieldName,
                            caption: column.Caption,
                            isHidden: column.Hidden,
                            dateFormat: column.DateFormat,
                            numberFormat: column.NumericFormat,
                            minWidth: column.MinWidth,
                            maxWidth: column.MaxWidth,
                            width: column.Width,
                            special: column.Special,
                            align: column.Align
                        })),
                        orderColumnMobile: {
                            text: card.OrderColumns?.Mobile,
                            info: card.OrderColumns?.MobileInfo
                        },
                        modificationColumns: modificationColumns.map(column => ({
                            key: column.FieldName,
                            caption: column.Caption,
                            isHidden: column.Hidden,
                            dateFormat: column.DateFormat,
                            numberFormat: column.NumericFormat,
                            minWidth: column.MinWidth,
                            maxWidth: column.MaxWidth,
                            width: column.Width,
                            align: column.Align
                        })),
                        modificationColumnMobile: {
                            text: card.ModificationColumns?.Mobile,
                            info: card.ModificationColumns?.MobileInfo
                        },
                        rejectedColumns: rejectedColumns.map(column => ({
                            key: column.FieldName,
                            caption: column.Caption,
                            isHidden: column.Hidden,
                            dateFormat: column.DateFormat,
                            numberFormat: column.NumericFormat,
                            minWidth: column.MinWidth,
                            maxWidth: column.MaxWidth,
                            width: column.Width,
                            align: column.Align
                        })),
                        rejectedColumnMobile: {
                            text: card.RejectedColumns?.Mobile,
                            info: card.RejectedColumns?.MobileInfo
                        },
                        pendingOrders,
                        underReviewOrders,
                        rejectedOrders,
                        scheduledOrders,
                        modificationData,
                        pastOrders,
                        balance: getCardValue(card.Balance, data) || 0,
                        extraUnused: getCardValue(card.ExtraUnused, data) || 0
                    }
                }
                default:
                    return {}
            }
        })
    }

    // change "Pending" to "Under Review" if it's past the cutoff for that record's start date
    public checkUnderReview (turnoutID: string, status: string, startDate: string, config): string {
        // Make sure config is defined
        if (!config) {
            return status
        }

        // Check if turnout has override values for cutoff, otherwise use values from config or 0 if unset
        const turnout = this.orderTurnouts.find(e => e.Turnout_ID === turnoutID) || ({} as any) // check to handle bad data
        const advanceDays = turnout.AdvanceDays !== undefined ? turnout.AdvanceDays : (config.AdvanceDays || 0)
        const cutoffHour = turnout.CutoffHour !== undefined ? turnout.CutoffHour : (config.CutoffHour || 0)
        const cutoffMinutes = turnout.CutoffMinutes !== undefined ? turnout.CutoffMinutes : (config.CutoffMinutes || 0)

        const now = new Date()
        const cutoff = advanceDays + 
            ( ( (now.getHours() * 60) + now.getMinutes() ) < ( ( cutoffHour * 60) + cutoffMinutes ) ? 0 : 1 )
        
        if (status === "Pending" && daysBetween(now, getSafeDate(startDate)) < cutoff) {
            return "Under Review"
        } else {
            return status
        }
    }
}